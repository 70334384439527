* {
  margin: 0;
  padding: 0;
}

body,
html,
#root {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

input:focus,
textarea:focus {
  outline-style: none;
  box-shadow: none;
  outline: none;
  border-color: transparent;
}

div[role='button'] * {
  /* pointer-events: none; */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}
